/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import { navigate } from "@reach/router";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import Footer from "~components/Footer";
import Layout from "~components/Layout";
import CursorHoverable from "~components/CursorHoverable";
import ReachOutCTA from "~components/ReachOutCTA";
import SEO from "~components/SEO";
import { shuffleArray } from "~utils/helpers";
import { parseProducts } from "~utils/shopify";

const PrintsPage = ({ data, location }) => {
  if (typeof window !== `undefined`) {
    navigate(`/`);
  }

  const {
    addToCart,
    darkMode,
    shuffledArtists,
    setShuffledArtists
  } = useContext(AppContext);
  const { device } = useContext(DocumentContext);

  const { frontmatter } = data.markdownRemark;

  useEffect(() => {
    if (!shuffledArtists) {
      const artists = parseProducts(data);

      if (
        !artists[0]?.frontmatter?.image?.childImageSharp?.fluid ||
        !artists[0]?.frontmatter?.hoverImage?.childImageSharp?.fluid
      ) {
        return;
      }

      const newlyShuffledArtists = shuffleArray(
        JSON.parse(JSON.stringify(artists))
      );

      setShuffledArtists(newlyShuffledArtists);
    }
  }, []);

  //

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout
        className={`prints-page w-full relative transition-background opacity-0 bg-${
          darkMode ? `black` : `white`
        } text-${darkMode ? `white` : `black`}`}
      >
        <section className="prints-page__list w-full relative pt-v12 xs:pt-v20">
          {shuffledArtists?.[0] && (
            <ul className="grid animation-appear-down-slow animation-delay-4">
              {shuffledArtists.map((product, productIndex) => {
                const hasImage =
                  product?.frontmatter?.image?.childImageSharp?.fluid;
                const hasHoverImage =
                  product?.frontmatter?.hoverImage?.childImageSharp?.fluid;

                return (
                  <li
                    key={product.slug}
                    className={`grid-end-10 xs:grid-end-24 grid-start-${
                      productIndex % 2 === 0 ? `2` : `14`
                    } xs:grid-start-1 relative block mb-v6 xs:mb-v12`}
                  >
                    {product?.slug && (
                      <>
                        <Link to={product.slug}>
                          <CursorHoverable className="prints-page__item w-full relative block overflow-hidden">
                            <div className="a2">
                              <div className="prints-page__image transition-opacity w-full h-full absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center">
                                {hasImage && (
                                  <Img
                                    className="w-full h-full relative block object-cover"
                                    fluid={
                                      product.frontmatter.image.childImageSharp
                                        .fluid
                                    }
                                    alt={product.frontmatter.artist}
                                  />
                                )}
                              </div>

                              {device && device === `desktop` && hasHoverImage && (
                                <div className="prints-page__hover w-full h-full absolute top-0 right-0 bottom-0 left-0 z-10 flex items-center justify-center">
                                  <Img
                                    className="w-full h-full relative block object-cover"
                                    fluid={
                                      product.frontmatter.hoverImage
                                        .childImageSharp.fluid
                                    }
                                    alt={product.frontmatter.artist}
                                  />
                                </div>
                              )}
                            </div>
                          </CursorHoverable>
                        </Link>

                        <article className="relative flex justify-between">
                          <CursorHoverable className="prints-page__item__link">
                            <Link to={product.slug}>
                              <h2
                                className={`prints-page__item__title mt-v1 xs:mt-v3 ${
                                  device && device === `desktop` ? `f5` : `b1`
                                }`}
                              >
                                {product.frontmatter.artist}
                              </h2>

                              <h3
                                className={`xs:mt-v2 ${
                                  device && device === `desktop` ? `b1` : `b2`
                                }`}
                              >
                                ${product.variants[0].price}
                              </h3>
                            </Link>
                          </CursorHoverable>

                          <CursorHoverable>
                            <button
                              type="button"
                              className={`${
                                product?.frontmatter?.soldOut === true
                                  ? `pointer-events-none opacity-25`
                                  : ``
                              } mt-v1 pt-v1 pl-v2 pb-v1 block relative hover-underline`}
                              onClick={() => {
                                if (product?.frontmatter?.soldOut === true) {
                                  return;
                                }

                                const productWithVariant = JSON.parse(
                                  JSON.stringify(product)
                                );

                                [productWithVariant.variant] = product.variants;

                                addToCart(productWithVariant);
                              }}
                            >
                              <span
                                className={`${
                                  device && device === `desktop` ? `b1` : `b2`
                                }`}
                              >
                                {product?.frontmatter?.soldOut === true
                                  ? `Sold out`
                                  : `Add to cart +`}
                              </span>
                            </button>
                          </CursorHoverable>
                        </article>
                      </>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </section>

        <ReachOutCTA />
      </Layout>

      <Footer />
    </>
  );
};

export default PrintsPage;

export const query = graphql`
  query PrintsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "artist-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            shopifyHandle
            title
            soldOut
            artist
            image {
              childImageSharp {
                fluid(maxWidth: 1280, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
              publicURL
            }
            hoverImage {
              childImageSharp {
                fluid(maxWidth: 1280, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
              publicURL
            }
          }
        }
      }
    }
    allShopifyProduct {
      edges {
        node {
          id
          title
          description
          handle
          images {
            originalSrc
          }
          productType
          vendor
          variants {
            id
            sku
            title
            image {
              originalSrc
            }
            price
            selectedOptions {
              name
              value
            }
          }
        }
      }
    }
    allShopifyAdminProduct {
      edges {
        node {
          products {
            handle
            variants {
              alternative_id
              title
            }
          }
        }
      }
    }
  }
`;
